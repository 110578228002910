import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import InputBase from '@material-ui/core/InputBase';
import RichText from '~components/RichText';
import { useCtaRegistration } from '~hooks/useAuth';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    zIndex: 15,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '32px',
    padding: '40px 60px',
    backgroundColor: '#D9D9D940',
    borderRadius: 10,
    [theme.breakpoints.down('xs')]: {
      padding: '32px 30px',
      gap: '28px'
    }
  },
  textArea: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 16,
    display: 'inline-flex'
  },
  title: {
    fontFamily: 'Inter',
    fontSize: '40px',
    fontWeight: 600,
    lineHeight: '118%',
    color: '#222222',
    marginTop: 0,
    marginBottom: 0,
    maxWidth: '660px',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: '32px',
      maxWidth: '240px',
      margin: 'auto'
    }
  },
  text: {
    marginTop: 0,
    marginBottom: 0,
    maxWidth: '450px',
    textAlign: 'center',
    '& > *': {
      fontSize: '16px',
      lineHeight: '140%',
      fontFamily: 'Inter',
      color: '#222222',
      marginBlockStart: 0,
      marginBlockEnd: 0,
      [theme.breakpoints.down('sm')]: {
        fontSize: '18px',
        lineHeight: '23px'
      }
    }
  },
  sentText: {
    fontFamily: 'Inter',
    fontSize: '14px',
    lineHeight: '140%',
    color: '#222222',
    marginTop: 0,
    marginBottom: 0,
    textAlign: 'center'
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    border: '1px solid #A7A7A7',
    borderRadius: 7,
    height: '45px',
    width: '100%',
    backgroundColor: '#FFFFFF'
  },
  emailInputRoot: {
    width: 'calc(100% - 193px)',
    fontSize: 16,
    lineHeight: '16px',
    height: '100%',
    backgroundColor: '#FFFFFF',
    marginLeft: 19,
    color: '#222222',
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100% - 100px)',
      fontSize: 14,
      lineHeight: '14px'
    }
  },
  button: {
    width: '193px',
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '16px',
    backgroundColor: '#000000',
    margin: 'auto',
    marginTop: '-1px',
    marginRight: 0,
    height: '45px',
    whiteSpace: 'nowrap',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderTopRightRadius: 7,
    borderBottomRightRadius: 7,
    textTransform: 'none',
    paddingLeft: '9px',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#222222',
      color: '#FFFFFF'
    },
    [theme.breakpoints.down('xs')]: {
      width: '100px',
      fontSize: 14,
      fontWeight: 600,
      lineHeight: '14px'
    }
  },
  errorText: {
    color: theme.palette.error.main
  },
  inputForm: {
    width: '100%',
    maxWidth: '650px'
  }
}));

const FreeContentEmailRegister = ({ title, description, mailchimpTag }) => {
  const classes = useStyles();

  const { register, handleSubmit } = useForm();
  const [regState, registerCta] = useCtaRegistration();
  const { isLoading, isError, error, data } = regState;

  const onSubmit = ({ username }) => {
    registerCta({ username, strategy: 'article-cta', tags: mailchimpTag });
  };

  return (
    <div className={classes.root}>
      <div className={classes.textArea}>
        <h3 className={classes.title}>{title}</h3>
        {description?.html && (
          <RichText externalClassName={classes.text} html={description?.html} verticalSpacing={0} />
        )}
      </div>
      {!data ? (
        <form onSubmit={handleSubmit(onSubmit)} className={classes.inputForm}>
          <div className={classes.inputContainer}>
            <InputBase
              placeholder="Enter Email Address"
              type="email"
              name="username"
              required
              inputProps={{ 'aria-label': 'enter email address' }}
              className={classes.emailInputRoot}
              inputRef={register}
              disabled={isLoading}
            />
            <Button className={classes.button} disableElevation type="submit" disabled={isLoading}>
              Submit
            </Button>
          </div>
          {isError && <small className={classes.errorText}>{error}</small>}
        </form>
      ) : (
        <p className={classes.sentText}>
          ✓ Acknowledged!
          <br />
          We will email you a link to watch as soon as possible.
        </p>
      )}
    </div>
  );
};

FreeContentEmailRegister.propTypes = {
  title: PropTypes.string,
  mailchimpTag: PropTypes.string.isRequired,
  description: PropTypes.shape({
    html: PropTypes.string
  })
};

FreeContentEmailRegister.defaultProps = {
  title: '',
  description: null
};

export default FreeContentEmailRegister;
