import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useUserContext } from '~context/UserContext/UserSessionContext';
import Container from '~components/Container';
import FreeContentEmailRegister from './FreeContentEmailRegister';

const useStyles = makeStyles({
  root: {
    backgroundColor: props => props.background_color,
    position: 'relative',
    paddingTop: '15px',
    paddingBottom: '15px',
    marginTop: '42px'
  }
});

const ArticleEmailCapture = ({
  main_title,
  description_text,
  mailchimp_tag,
  background_color,
  containerSize
}) => {
  const classes = useStyles({ background_color });

  const { isUserStateLoading, marketingUser } = useUserContext().session;
  const [showSlice, setShowSlice] = useState(false);

  useEffect(() => {
    /**
     * Here we are showing the slice if the user is a not logged in user once user state loading completes.
     * When user gets registered, user automatically getting logged in. At that time also we should show this slice.
     * Therefore, once show slice set to true, we should not make it false again.
     */
    if (!showSlice && !isUserStateLoading && marketingUser?.isGuestUser) {
      setShowSlice(true);
    }
  }, [showSlice, isUserStateLoading, marketingUser?.isGuestUser]);

  if (!showSlice) {
    return null;
  }

  return (
    <>
      <div className={classes.root}>
        <Container maxWidth={containerSize}>
          <FreeContentEmailRegister
            title={main_title || 'Try some of our videos'}
            description={
              description_text || {
                html: '<p>Enter your email and we’ll send you three of our favorite videos for free.</p>'
              }
            }
            mailchimpTag={mailchimp_tag || '3-Favorite-Videos'}
          />
        </Container>
      </div>
    </>
  );
};

ArticleEmailCapture.propTypes = {
  background_color: PropTypes.string,
  main_title: PropTypes.string,
  description_text: PropTypes.shape({
    html: PropTypes.string
  }),
  mailchimp_tag: PropTypes.string,
  containerSize: PropTypes.string
};

ArticleEmailCapture.defaultProps = {
  containerSize: 'md',
  background_color: '#FFFFFF',
  main_title: '',
  description_text: null,
  mailchimp_tag: ''
};

export default ArticleEmailCapture;
